var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('WidgetsAvailableStyled', _vm._l(_vm.widgetsAvailableMapped, function (widget) {
    return _c('WidgetAvailable', {
      key: widget.id,
      attrs: {
        "widget": widget
      },
      on: {
        "setWidgetType": function setWidgetType($event) {
          return _vm.$emit('setWidgetType', widget);
        }
      }
    });
  }), 1);
}
var staticRenderFns = []

export { render, staticRenderFns }