<template>
  <WidgetsAvailableStyled>
    <WidgetAvailable
      v-for="widget in widgetsAvailableMapped"
      :key="widget.id"
      :widget="widget"
      @setWidgetType="$emit('setWidgetType', widget)"
    />
  </WidgetsAvailableStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import get from 'lodash/get'

import WidgetAvailable from './WidgetsAvailable/WidgetAvailable'

const WidgetsAvailableStyled = styled('div')`
  display: grid;
  grid-template-columns: repeat(4, minmax(6rem, 1fr));
  grid-auto-rows: minmax(6rem, 1fr);
`

export default {
  props: {
    selectedWidget: {
      type: Object,
    },
    widgetsAvailable: {
      type: Array,
      required: true,
    },
    dimensionsSelected: {
      type: Array,
      required: true,
    },
  },
  components: {
    WidgetsAvailableStyled,
    WidgetAvailable,
  },
  computed: {
    widgetsAvailableMapped() {
      return this.widgetsAvailable.map(wa => ({
        ...wa,
        isSelected: get(this.selectedWidget, 'id') === wa.id,
      }))
    },
  },
}
</script>
