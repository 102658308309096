<template>
  <WidgetAvailableStyled
    :class="{ enabled: widget.isEnabled, selected: widget.isSelected, disabled: !widget.isEnabled }"
    v-tooltip.bottom="tooltip"
    @click="selectedWidget(widget)"
  >
    <TalpaIcon :scope="'WidgetTypes'" :name="icon" />
    <span class="name">{{ $t('widgetTypes.' + widget.name) }}</span>
  </WidgetAvailableStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { VTooltip } from 'v-tooltip'

import { TalpaIcon } from '@common/components'

import { buttonReset } from '@styles/mixins'

const WidgetAvailableStyled = styled('button')`
  ${buttonReset}
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.disabled {
    opacity: 0.5;
    color: ${p => p.theme.colors.navFontNormal};
  }

  &.enabled {
    color: ${p => (p.theme.isDark ? p.theme.colors.navFontNormal : p.theme.colors.primary)};
    &:hover {
      color: ${p => (p.theme.isDark ? p.theme.colors.primary : p.theme.colors.secondary)};
    }
  }
  &.selected {
    color: ${p => (p.theme.isDark ? p.theme.colors.primary : p.theme.colors.secondary)};
  }
  &:first-child {
    margin-top: 0;
  }
  .name {
    margin-top: 0.5rem;
  }
`

export default {
  props: {
    widget: {
      type: Object,
      required: true,
    },
  },
  directives: {
    tooltip: VTooltip,
  },
  components: {
    WidgetAvailableStyled,
    TalpaIcon,
  },
  computed: {
    icon() {
      if (!this.widget.name) {
        return null
      }
      return (
        this.widget.name
          .toString()
          .split('_')
          .map(s => {
            s = s.toLowerCase()
            return s.charAt(0).toUpperCase() + s.slice(1)
          })
          .join('') + 'Icon'
      )
    },
    tooltip() {
      return {
        content: this.tooltipContent,
        container: '#app',
        classes: 'widget',
        placement: 'bottom',
      }
    },
    tooltipContent() {
      return this.widget.isEnabled ? this.$t('messages.maxDimensionsAllowed') + `: ${this.widget.maxDimensions}` : this.widget.reason
    },
  },
  methods: {
    selectedWidget(widget) {
      if (widget.isEnabled) {
        this.$emit('setWidgetType')
      }
    },
  },
}
</script>
