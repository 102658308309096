var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('WidgetAvailableStyled', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip.bottom",
      value: _vm.tooltip,
      expression: "tooltip",
      modifiers: {
        "bottom": true
      }
    }],
    class: {
      enabled: _vm.widget.isEnabled,
      selected: _vm.widget.isSelected,
      disabled: !_vm.widget.isEnabled
    },
    on: {
      "click": function click($event) {
        return _vm.selectedWidget(_vm.widget);
      }
    }
  }, [_c('TalpaIcon', {
    attrs: {
      "scope": 'WidgetTypes',
      "name": _vm.icon
    }
  }), _c('span', {
    staticClass: "name"
  }, [_vm._v(_vm._s(_vm.$t('widgetTypes.' + _vm.widget.name)))])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }